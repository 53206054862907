import { css } from "@emotion/core";
import { Tablet } from "../constants/responsive";

export const blinkingEyeStyle = css`
  position: absolute;
  width: 100%;
  z-index: 21;
`;

export const headerIconStyle = css`
  width: 56px;
  padding-top: 5px;
  cursor: pointer;
  ${Tablet} {
    width: 70px;
  }
`;
