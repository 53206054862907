import React, { useState } from "react";
import { OutboundLink } from "gatsby-plugin-gtag";
import { css, Global } from "@emotion/core";
import { Link } from "gatsby-plugin-intl";
import Container from "../Container";
import Logo from "../../svgs/rice/logo.svg";
import Letter from "../../svgs/icons/email.svg";
import Phone from "../../svgs/icons/phone.svg";
import { Tablet } from "../../constants/responsive";
import Menu from "../Menu";
import { useLocation } from "@reach/router";
import InternalLink from "../InternalLink";
import { headerIconStyle } from "../../constants/styles";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const { pathname } = useLocation();
  return (
    <>
      <Global
        styles={css`
          body {
            overflow-y: ${menuOpened ? "hidden" : "visible"};
          }
        `}
      />

      <Menu menuOpened={menuOpened} onClick={() => setMenuOpened(false)} />

      <header
        css={css`
          z-index: 50;
          position: fixed;
          top: 0px;
          background: #fff;
          min-width: 320px;
          width: 100%;
          display: flex;
          justify-content: center;
          height: 70px;
          user-select: none;
          transition: all 0.2s ease;
          svg {
            fill: #212121;
          }
        `}
      >
        <Container>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 100%;
            `}
          >
            <div
              css={css`
                width: 70px;
              `}
            >
              <div
                css={css`
                  .burger {
                    display: flex;
                    outline: none;
                    border: 0px;
                    margin-left: -20px;
                    padding-left: 20px;
                    width: 70px;
                    height: 70px;

                    transition: all 250ms ease;
                    background: transparent;
                    cursor: pointer;
                  }

                  .burger:before,
                  .burger:after {
                    margin-top: 32px;
                    content: "";
                    height: 4px;
                    width: 24px;
                    ${Tablet} {
                      width: 32px;
                    }
                    position: absolute;
                    background: #212121;
                    transition: all 0.25s ease;
                    will-change: transform;
                    border-radius: 100px;
                  }

                  .burger:before {
                    transform: translateY(-6px);
                  }

                  .burger:after {
                    transform: translateY(6px);
                  }

                  .active.burger:before {
                    transform: translateY(0) rotate(45deg);
                  }

                  .active.burger:after {
                    transform: translateY(0) rotate(-45deg);
                  }
                `}
              >
                <div
                  onClick={() => setMenuOpened(!menuOpened)}
                  className={menuOpened ? "active burger" : "burger"}
                ></div>
              </div>
            </div>

            {(pathname === "/en/" || pathname === "/ja/") && !menuOpened ? (
              <InternalLink to={"/#top"} css={headerIconStyle}>
                <Logo />
              </InternalLink>
            ) : (
              <Link
                to="/"
                onClick={(e) => {
                  setMenuOpened(false);
                  if (menuOpened) {
                    e.preventDefault();
                  }
                }}
                css={headerIconStyle}
              >
                <Logo />
              </Link>
            )}
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  border-radius: 25px;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  font-weight: bold;
                  cursor: pointer;
                  transition: all 0.2s ease;
                  font-weight: 700;
                  text-align: center;
                  height: 70px;
                  width: 70px;
                  margin-left: 20px;
                  margin-right: -20px;
                  padding-left: -20px;
                  padding-right: 20px;
                  gap: 20px;
                `}
              >
                <OutboundLink href="tel:+85296475835">
                  <Phone
                    css={css`
                      height: 100%;
                      width: 25px;
                      ${Tablet} {
                        width: 32px;
                      }
                    `}
                  />
                </OutboundLink>
                <OutboundLink href="mailto:hello@ricerobotics.com">
                  <Letter
                    css={css`
                      height: 100%;
                      width: 25px;
                      ${Tablet} {
                        width: 32px;
                      }
                    `}
                  />
                </OutboundLink>
              </div>
            </div>
          </div>
        </Container>
      </header>
    </>
  );
};

export default Header;
