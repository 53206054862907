import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";

import Styles from "../Styles";
import Header from "../Header";
import Main from "../Main";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import { useMount, useUnmount } from "react-use";

const Layout = ({ children }) => {
  const [width, setWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 480,
  );

  const updateViewportMeta = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 480) {
        setWidth(window.innerWidth);
      }
      if (width !== 480 && window.innerWidth > 480) {
        setWidth(480);
      }
    }
  };

  useMount(() => {
    if (typeof window !== "undefined") {
      updateViewportMeta();
      window.addEventListener("resize", updateViewportMeta);
    }
  });
  useUnmount(() => {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", updateViewportMeta);
    }
  });

  const scale = width < 480 ? width / 480 : 1;

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content={`width=${
            width < 480 ? "480" : "device-width"
          }, initial-scale=${scale},maximum-scale=${scale}, minimum-scale=${scale},user-scalable=no`}
        />
      </Helmet>
      <Styles />
      <div
        id="top"
        css={css`
          position: absolute;
          top: 0;
        `}
      />

      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
