import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const InternalLink = ({ to, children, onClick, ...rest }) => {
  const intl = useIntl();
  const link = `/${intl.locale}${to}`;

  return (
    <AnchorLink {...rest} to={link} onAnchorLinkClick={onClick}>
      {children}
    </AnchorLink>
  );
};

export default InternalLink;
