import React from "react";
import { Global, css } from "@emotion/core";
import { Desktop, Tablet } from "../../constants/responsive";

const Styles = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: "Proxima Nova A";
          font-style: normal;
          font-weight: 400;
          src: url(/fonts/ProximaNovaA-Regular.woff);
        }
        @font-face {
          font-family: "Proxima Nova A";
          font-style: normal;
          font-weight: 700;
          src: url(/fonts/ProximaNovaA-Bold.woff);
        }
        @font-face {
          font-family: "Noto Sans JP";
          font-style: normal;
          font-weight: 400;
          src: url(/fonts/noto-sans-jp-v27-japanese-regular.woff);
        }
        @font-face {
          font-family: "Noto Sans JP";
          font-style: normal;
          font-weight: 700;
          src: url(/fonts/noto-sans-jp-v27-japanese-700.woff);
        }
        body {
          font-family: "Proxima Nova A", "Noto Sans JP", sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          overflow-x: hidden;
        }
        *::selection {
          background: #dedede; /* WebKit/Blink Browsers */
        }
        *::-moz-selection {
          background: #dedede; /* Gecko Browsers */
        }
        * {
          vertical-align: baseline;
          box-sizing: border-box;
          font-weight: inherit;
          font-family: inherit;
          font-style: inherit;
          font-size: 100%;
          border: 0 none;
          outline: 0;
          padding: 0;
          margin: 0;
          overscroll-behavior: none;

          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        *::-webkit-scrollbar {
          display: none;
        }

        a,
        a:active,
        a:visited {
          color: #bdbdbd;
          transition: all 0.2s ease;
          text-decoration: none;
        }
        a:hover {
          color: white;
        }
        b {
          font-weight: bold;
        }
        strong {
          color: red;
        }
        em {
          display: inline;
          font-weight: 300;
        }
        h1 {
          display: inline-block;
          font-size: 35px;
          margin-bottom: 10px;
          font-weight: bold;
          line-height: 1;
          ${Tablet} {
            font-size: 35px;
          }
          ${Desktop} {
            font-size: 50px;
          }
        }
        h2 {
          display: inline-block;
          font-size: 20px;
          margin-bottom: 24px;
          line-height: 1.5;
          ${Tablet} {
            font-size: 20px;
          }
          ${Desktop} {
            font-size: 30px;
          }
        }
        h3 {
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.5;
          ${Tablet} {
            font-size: 16px;
          }
          ${Desktop} {
            font-size: 20px;
          }
        }
        p {
          font-size: 14px;
          line-height: 1.5;
          ${Tablet} {
            font-size: 14px;
          }
          ${Desktop} {
            font-size: 18px;
          }
        }
        hr {
          flex-basis: 100%;
          height: 0;
          margin: 0;
          border: 0;
        }
      `}
    />
  );
};

export default Styles;
