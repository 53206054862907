import React from "react";
import { css } from "@emotion/core";
import { changeLocale, useIntl } from "gatsby-plugin-intl";

const LanguageOption = ({ code, text }) => {
  const intl = useIntl();
  const active = intl.locale === code;
  if (active) return false;
  return (
    <div
      css={css`
        text-align: center;
        color: black;
        cursor: pointer;
      `}
      onClick={() => changeLocale(code)}
    >
      {text}
    </div>
  );
};

const Language = () => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        height: 100%;
      `}
    >
      <LanguageOption code="ja" text="日本語" />
      <LanguageOption code="en" text="EN" />
    </div>
  );
};

export default Language;
