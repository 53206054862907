import React from "react";
import { css } from "@emotion/core";
import { Tablet } from "../../constants/responsive";
const Main = ({ children }) => {
  return (
    <main
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        min-width: 320px;
        margin-top: 80px;
        ${Tablet} {
          margin-top: 120px;
        }
      `}
    >
      {children}
    </main>
  );
};

export default Main;
