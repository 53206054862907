export const MobileThreshold = 767;
export const TabletThreshold = 768;
export const DesktopThreshold = 1200;

export const MaxMobileWidth = `(max-width: ${MobileThreshold}px)`;
export const MinTabletWidth = `(min-width: ${TabletThreshold}px)`;
export const MinDesktopWidth = `(min-width: ${DesktopThreshold}px)`;

export const Mobile = `@media ${MaxMobileWidth}`;
export const Tablet = `@media ${MinTabletWidth}`;
export const Desktop = `@media ${MinDesktopWidth}`;

export const HeightThresold = "@media (max-height: 480px)";
